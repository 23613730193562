/* CSS for smart */
@import "../../../../../../vrtx/groups/design-uio1/src/resources/css/util.less";
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

body,
.vrtx-introduction {
  font-family: @font-body;
}

.not-for-ansatte {
  .header-search-collapsable .header-search-expand {
    background-color: @color-contrast !important;
  }
  #head-wrapper {
    background-color: @color-primary;
    #head {
      height: 200px;
      .partnerlogin {
        font-size: 1.5rem;
        margin-top: 1px;
        color: @color-neutral;
      }
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        > .vrtx-login-manage-component > a,
        .not-for-ansatte .head-menu > ul > li a {
          color: @color-light;
        }
      }

      #header {
        height: 200px;
        a.head-logo {
          position: absolute;
          width: 550px;
          height: 50;
          padding-bottom: 20px;
          padding-top: 20px;
          color: @color-light;
          top: 0px;
          span {
            padding: 8px 0;
            left: 180px;
            position: absolute;
            top: 75px;
            .header-title {
              font-size: 5rem;
              left: 0;
              top: -15px;
            }
          }
        }

        a.head-logo:hover,
        a.head-logo:focus {
          color: @color-contrast !important;
          text-decoration: none;
        }
      }
    }
    .vrtx-frontpage-box-picture img {
      margin-top: -36px;
    }
    .uio-app-name {
      display: none;
    }

    .header-search {
      top: 18px;
      z-index: 10;

      input[type="text"] {
        background: @color-neutral--light;
        .placeholderColor(@color-dark, @color-dark) !important;
        color: @color-neutral--dark;
      }
      .header-search-expand,
      button {
        background-color: @color-contrast;
        color: @color-dark;
        &:focus,
        &:hover {
          background-color: @color-primary--dark;
          color: @color-light;
        }
      }
    }
  }
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main input[type="button"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
#main input[type="cancel"]:not(.red),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
  background-color: @color-primary;
  &:focus,
  &:hover {
    background-color: @color-neutral--light;
  }
}

#globalnav {
  background: @color-contrast none repeat scroll 0 0;
  ul {
    li,
    li.vrtx-active-item {
      background: @color-contrast;
      a {
        padding: 12px 15px 13px;
        color: @color-dark;
      }
      a:hover,
      a:focus {
        text-decoration: underline;
        color: @color-dark !important;
        background-color: @color-contrast !important;
      }
    }
    li.vrtx-active-item a {
      background-color: @color-light !important;
      color: @color-dark !important;
    }
  }
}

.vrtx-frontpage-box-picture {
  z-index: -1;
}

.vrtx-frontpage-box.vrtx-header-false.vrtx-more-false.content-over-image-left
  .vrtx-box-content {
  margin-top: 40px !important;
}

.feed-hide-first .vrtx-feed:first-child li.item-1 {
  display: none;
}

.feed-hide-2-3 {
  li.item-2,
  li.item-3 {
    display: none;
  }
}

#vrtx-main-content-3.feed-hide-first.third-box-right .item-title {
  font-size: 22px;
  font-size: 2.2rem;
  margin-bottom: 10px;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

.contributors {
  img {
    border-radius: 75px;
  }
  .font-size(16);
}
blockquote {
  border-left: 4px solid @color-contrast;
}
#main {
  #vrtx-daily-events .vrtx-daily-events-listing a.vrtx-image ~ *,
  .vrtx-resources .vrtx-daily-events-listing a.vrtx-image ~ *,
  #vrtx-daily-events .vrtx-resource a.vrtx-image ~ *,
  .vrtx-resources .vrtx-resource a.vrtx-image ~ * {
    margin-left: 325px;
  }
  #vrtx-daily-events .vrtx-daily-events-listing a.vrtx-image,
  .vrtx-resources .vrtx-daily-events-listing a.vrtx-image,
  #vrtx-daily-events .vrtx-resource a.vrtx-image,
  .vrtx-resources .vrtx-resource a.vrtx-image {
    width: 300px;
  }
  .vrtx-event-component
    .vrtx-event-component-item
    .vrtx-event-component-picture {
    width: 170px;
  }
  .row-all-colored,
  .vrtx-frontpage-box.grey-box,
  .vrtx-frontpage-box.grey-clip-box,
  .vrtx-frontpage-box.grey-box-light {
    background-color: @color-neutral--air;
  }
}

.vrtx-frontpage-box {
  &.vrtx-frontpage-box-picture[class*="third-box-"]:not(.third-box-double),
  &.vrtx-frontpage-box-picture[class*="half-box-"] {
    .vrtx-frontpage-box-picture img {
      width: 170px;
    }
    h2,
    .vrtx-box-content {
      margin-left: 190px;
    }
  }
}

.vrtx-frontpage-full-width.total-main #main .row,
.vrtx-frontpage-full-width.total-main #main #vrtx-change-language-link,
.vrtx-frontpage-full-width.total-main #main .vrtx-back,
.vrtx-frontpage-full-width.total-main #main .vrtx-context-box,
.vrtx-frontpage-full-width.total-main #main h1,
.vrtx-frontpage-full-width.total-main #main #vrtx-frontpage-introduction {
  padding-bottom: 20px;
  height: auto;
}

#vrtx-structured-event .vrtx-date-info {
  display: none;
}
#vrtx-structured-event .vrtx-introduction {
  border-bottom: 6px solid #eaeaea;
  padding-bottom: 40px;
}

#left-main .vrtx-breadcrumb-menu li.vrtx-child > span.vrtx-after-marked,
#left-main .vrtx-breadcrumb-menu li.vrtx-child > a.vrtx-after-marked,
#left-main .vrtx-breadcrumb-menu li.vrtx-child > span.vrtx-marked,
#left-main .vrtx-breadcrumb-menu li.vrtx-child > a.vrtx-marked {
  border-top: none;
}

table th {
  font-weight: bold;
}
table.vrtx-person-listing th.vrtx-person-listing-phone,
td.vrtx-person-listing-phone {
  display: none;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @color-neutral--light;
}

//footer
#footer-wrapper {
  color: @color-neutral--dark;
  #footers {
    a {
      color: @color-link;
    }
    .col-3-5 {
      display: flex;
      column-gap: 40px;
      width: 70%;
    }
    .col-2-5 {
      width: 30%;
      .menu-label {
        white-space: nowrap;
      }
    }
    background: none;
    .responsible-login .content {
      margin-left: 60px;
      margin-right: 0;
    }
  
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../images/dropdown-black.png") no-repeat
        scroll right center / 22px auto;
    }
    h2 {
      color: @color-neutral--dark;
      white-space: nowrap;
    }
    .eu-flag > span {
      padding-top: 20px;
      float: left;
      padding-left: 10px;
    }
    .eu-flag > img {
      padding: 20px 20px 20px 0;
      float: left;
    }
    .uio-logo {
      margin-top: 28px;
      > a:hover,
      > a:focus {
        background-color: @color-neutral--light !important;
        box-shadow: none !important;
      }
    }

    .social-components {
      display: flex;
      padding: 5px 0 12px;
      margin-left: 0px;
      margin-top: 0px;
      a {
        display: inline-block;
        &.facebook {
          background: transparent url("../uio1/images/social-list/black-svg/facebook.svg") no-repeat;
          background-size: 100%;
        }
        &.twitter-x {
          background: transparent url("../uio1/images/social-list/black-svg/twitter-x.svg") no-repeat;
          background-size: 100%;
        }
        
        &.facebook,
        &.twitter-x {
          color: transparent;
          margin-left: 3px;
          margin-right: 20px;
          padding: 0 0 17px 22px;
          width: 45px;
          height: 45px;
          &:hover,
          &:focus {
            color: transparent;
            opacity: 0.6;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #vrtx-frontpage #main {
    margin-top: 0 !important;
    .vrtx-frontpage-box {
      &.half-box-left,
      &.half-box-right {
        margin-bottom: 0;
        margin-top: 0 !important;
      }
      .vrtx-box-content {
        margin-bottom: 18px;
      }
      &.content-over-image-left,
      &.content-over-image-right,
      &.featured-one-big-two-column {
        &:first-child {
          margin-bottom: -33px;
          margin-top: -15px;
        }
      }
    }
    .vrtx-frontpage-box.content-over-image-left .vrtx-box-content > p,
    .vrtx-frontpage-box.content-over-image-right .vrtx-box-content > p,
    .vrtx-frontpage-box.featured-one-big-two-column
      .vrtx-featured-item-1
      .vrtx-box-content
      > p {
      display: block !important;
    }
    .vrtx-frontpage-box.content-over-image-left
      > .vrtx-box-content
      > :nth-child(2),
    .vrtx-frontpage-box.content-over-image-right
      > .vrtx-box-content
      > :nth-child(2),
    .vrtx-frontpage-box.featured-one-big-two-column
      > .vrtx-box-content
      > :nth-child(2) {
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .grid-container {
      margin-bottom: 0px;
    }
  }
  .not-for-ansatte {
    .grid-container.full-width-picture-bg {
      margin-bottom: 0 !important;
    }
    #head-wrapper #head {
      &,
      #header {
        height: auto;
      }
      #header  {
        padding: 25px 15px 15px 15px;
        a.head-logo {
          position: static;
          span {
            top: 60px;
            left: 180px;
            font-size: 75%;
            .header-title {
              font-size: 3.5rem;
            }
          }
        }
      }
    }
    .flex {
      margin-left: 0px;
      align-items: flex-start;
      width: 100%;
    }

    #menu-wrapper .menu li.active {
      background: @color-contrast none repeat scroll 0 0;
    }
    #lang-link a {
      display: none !important;
    }
  }
  /* Show item 2 and 3 in 1. content-box. (Hidden from 2. box below) */
  #vrtx-main-content-2.feed-hide-2-3 {
    margin-bottom: -25px !important;
    li.item-2,
    li.item-3 {
      display: block;
    }
  }
  /* Hide item 2 and 3 from 2. content-box so they don't show up beneath show-more-button */
  #vrtx-main-content-3.feed-hide-first.third-box-right {
    display: none;
  }

  #vrtx-main-content-2 {
    .vrtx-image {
      margin-bottom: 0 !important;
    }
    .item-1 .item-title {
      font-size: 20px !important;
      font-size: 2rem !important;
      line-height: 32px !important;
      line-height: 3.2rem !important;
    }
    .item-2,
    .item-3 {
      .item-title {
        font-size: 16px !important;
        font-size: 1.6rem !important;
        line-height: 28px !important;
        line-height: 2.8rem !important;
      }
    }
  }
  #footer-wrapper {
    #footers {
      .col-3-5 {
        flex-wrap: wrap;
        flex-direction: column;
      }
      .responsible-login .content {
        margin-left: 0; 
      }
    }
  }
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #1a6667;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-contrast: #f8b430;
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #4f4f4f;
@color-neutral: #aaabab;
@color-neutral--light: #e4e4e4;
@color-neutral--air: #f5f5f5;
@color-lines: #f0f0f0;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #2b2b2b;

/*------------------------------------*\
#Font
\*------------------------------------*/
@font-body: "Open Sans", sans-serif;
